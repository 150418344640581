import { ua } from './modules/ua'
import inView from 'in-view'
import Swiper from 'swiper/bundle'
// import lottie from 'lottie-web'
import smoothscroll from 'smoothscroll-polyfill'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  large: 'is-large',
  nav: 'view-nav',
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.header = document.querySelector('.header')
    this.swiper = []
    this.init()
  }

  init() {
    this.initPages()
  }

  initPages() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)
    this.setFillHeight()
    document.querySelector('.loader').classList.add(klass.hidden)

    let viewNavFlag = false
    window.addEventListener('resize', () => {
      if (!viewNavFlag) return
      this.setFillHeight()
    })

    // ナビゲーション開閉
    Array.from(document.querySelectorAll('.trigger-nav')).forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault()
        this.setFillHeight()
        document.querySelector('body').classList.toggle(klass.nav)
        viewNavFlag = !viewNavFlag
      })
    })

    inView('.in-view').on('enter', (el) => {
      el.classList.add('is-view')
    })
    inView.offset(300)

    window.addEventListener('scroll', () => {
      if (window.scrollY > 5) {
        document.querySelector('body').classList.add('is-scroll')
      } else {
        document.querySelector('body').classList.remove('is-scroll')
      }
    })

    /**
     * スムーススクロール
     */
    smoothscroll.polyfill()
    Array.from(document.querySelectorAll('.js-smooth-scroll')).forEach((link) => {
      link.addEventListener('click', (e) => {
        const ankerTarget = e.target
        e.preventDefault()
        document.querySelector('body').classList.remove('view-nav')
        const targetId = ankerTarget.hash
        const targetElement = document.querySelector(targetId)
        const rectTop = targetElement.getBoundingClientRect().top
        const offsetTop = window.pageYOffset
        const buffer = 0
        const top = rectTop + offsetTop - buffer
        window.scrollTo({
          top,
          behavior: 'smooth',
        })
      })
    })

    if (this.body.classList.contains('page-index')) {
      /**
       * TOP スライドギャラリー起動
       * @type {Swiper}
       */
      this.swiper = new Swiper('.main-visual', {
        effect: 'fade',
        speed: 1000,
        loop: true,
        autoplay: {
          delay: 3000,
        },
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      })
    }

    if (this.body.classList.contains('page-single')) {
      /**
       * Single スライドギャラリー起動
       * @type {Swiper}
       */
      if (document.querySelectorAll('.post-swiper')) {
        Array.from(document.querySelectorAll('.post-swiper')).forEach((slider, index) => {
          const sliderClass = `post-swiper${index}`
          slider.classList.add(sliderClass)
          setTimeout(() => {
            const postSwiper = new Swiper(`.${sliderClass}`, {
              speed: 400,
              loop: true,
              centeredSlides: true,
              autoplay: {
                delay: 3000,
              },
              slidesPerView: 'auto',
              spaceBetween: 0,
            })
          }, 100)
        })
      }

      if (document.querySelector('.main-slide')) {
        /**
         * Main スライドギャラリー起動
         * @type {Swiper}
         */
        this.swiper = new Swiper('.main-slide', {
          speed: 400,
          loop: true,
          centeredSlides: true,
          autoplay: {
            delay: 3000,
          },
          slidesPerView: 'auto',
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
        })
      }
    }
  }

  setFillHeight() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.apps = new App()
})
